/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { t } from '@superset-ui/core';

export const AGGREGATES = {
  AVG: 'AVG',
  COUNT: 'COUNT',
  COUNT_DISTINCT: 'COUNT_DISTINCT',
  MAX: 'MAX',
  MIN: 'MIN',
  SUM: 'SUM',
  UNIQUE: 'UNIQUE',
  PERCENTILE: 'PERCENTILE',
};
export const AGGREGATES_OPTIONS = Object.values(AGGREGATES);

export const PERCENTILES = {
  PERCENTILE_99th: '99th',
  PERCENTILE_95th: '95th',
  PERCENTILE_90th: '90th',
  PERCENTILE_75th: '75th',
  PERCENTILE_50th: '50th',
  PERCENTILE_25th: '25th',
  PERCENTILE_10th: '10th',
  PERCENTILE_5th: '5th',
  PERCENTILE_1th: '1th',
};

export const PERCENTILE_OPTIONS = Object.values(PERCENTILES);

export enum Operators {
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  In = 'IN',
  NotIn = 'NOT_IN',
  // Like = 'LIKE',
  // CaseInsensitiveLike = 'ILIKE',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LatestPartition = 'LATEST_PARTITION',
  IsTrue = 'IS_TRUE',
  IsFalse = 'IS_FALSE',
  TemporalRange = 'TEMPORAL_RANGE',
  Contains = 'CONTAINS',
  ContainsSensitive = 'CONTAINS_SENSITIVE',
  StartsWith = 'STARTS_WITH',
  NotStartsWith = 'NOT_STARTS_WITH',
  EndsWith = 'ENDS_WITH',
  NotEndsWith = 'NOT_ENDS_WITH',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  NotContains = 'NOT_CONTAINS',
  NotContainsSensitive = 'NOT_CONTAINS_SENSITIVE',
  IsBooleanEmpty = 'IS_BOOLEAN_EMPTY',
  IsBooleanNotEmpty = 'IS_BOOLEAN_NOT_EMPTY',
}

export interface OperatorType {
  display: string;
  operation: string;
}

export const OPERATOR_ENUM_TO_OPERATOR_TYPE: {
  [key in Operators]: OperatorType;
} = {
  [Operators.Equals]: { display: t('Equal to (=)'), operation: '==' },
  [Operators.NotEquals]: { display: t('Not equal to (≠)'), operation: '!=' },
  [Operators.LessThan]: { display: t('Less than (<)'), operation: '<' },
  [Operators.LessThanOrEqual]: {
    display: t('Less or equal (<=)'),
    operation: '<=',
  },
  [Operators.GreaterThan]: { display: t('Greater than (>)'), operation: '>' },
  [Operators.GreaterThanOrEqual]: {
    display: t('Greater or equal (>=)'),
    operation: '>=',
  },
  [Operators.In]: { display: t('In'), operation: 'IN' },
  [Operators.NotIn]: { display: t('Not in'), operation: 'NOT IN' },
  // [Operators.Like]: { display: t('Like'), operation: 'LIKE' },
  // [Operators.CaseInsensitiveLike]: {
  //   display: t('Like (case insensitive)'),
  //   operation: 'ILIKE',
  // },
  [Operators.Contains]: {
    display: 'Contains',
    operation: 'CONTAINS',
  },
  [Operators.ContainsSensitive]: {
    display: 'Contains (case sensitive)',
    operation: 'CONTAINS_SENSITIVE',
  },
  [Operators.NotContains]: {
    display: 'Not contains',
    operation: 'NOT_CONTAINS',
  },
  [Operators.NotContainsSensitive]: {
    display: 'Not contains (case sensitive)',
    operation: 'NOT_CONTAINS_SENSITIVE',
  },
  [Operators.StartsWith]: { display: 'Starts with', operation: 'STARTS_WITH' },
  [Operators.NotStartsWith]: {
    display: 'Not starts with',
    operation: 'NOT_STARTS_WITH',
  },
  [Operators.EndsWith]: { display: 'Ends with', operation: 'ENDS_WITH' },
  [Operators.NotEndsWith]: {
    display: 'Not ends with',
    operation: 'NOT_ENDS_WITH',
  },
  [Operators.IsNotNull]: {
    display: t('Is not null'),
    operation: 'IS NOT NULL',
  },
  [Operators.IsNull]: { display: t('Is null'), operation: 'IS NULL' },
  [Operators.LatestPartition]: {
    display: t('use latest_partition template'),
    operation: 'LATEST PARTITION',
  },
  [Operators.IsTrue]: { display: t('Is true'), operation: '==' },
  [Operators.IsFalse]: { display: t('Is false'), operation: '==' },
  [Operators.TemporalRange]: {
    display: t('TEMPORAL_RANGE'),
    operation: 'TEMPORAL_RANGE',
  },
  [Operators.IsEmpty]: { display: 'Is empty', operation: 'IS EMPTY' },
  [Operators.IsNotEmpty]: {
    display: 'Is not empty',
    operation: 'IS NOT EMPTY',
  },
  [Operators.IsBooleanEmpty]: {
    display: 'Is empty',
    operation: 'IS BOOLEAN EMPTY',
  },
  [Operators.IsBooleanNotEmpty]: {
    display: 'Is not empty',
    operation: 'IS BOOLEAN NOT EMPTY',
  },
};

export const OPERATORS_OPTIONS = Object.values(Operators) as Operators[];

export const TABLE_ONLY_OPERATORS = [
  // Operators.Like,
  // Operators.CaseInsensitiveLike,
  Operators.Contains,
  Operators.ContainsSensitive,
  Operators.NotContains,
  Operators.NotContainsSensitive,
  Operators.StartsWith,
  Operators.NotStartsWith,
  Operators.EndsWith,
  Operators.NotEndsWith,
];
export const HAVING_OPERATORS = [
  Operators.Equals,
  Operators.NotEquals,
  Operators.LessThan,
  Operators.LessThanOrEqual,
  Operators.GreaterThan,
  Operators.GreaterThanOrEqual,
];
export const MULTI_OPERATORS = new Set([Operators.In, Operators.NotIn]);
// CUSTOM_OPERATORS will show operator in simple mode,
// but will generate customized sqlExpression
export const CUSTOM_OPERATORS = new Set([
  Operators.LatestPartition,
  Operators.TemporalRange,
]);
// DISABLE_INPUT_OPERATORS will disable filter value input
// in adhocFilter control
export const DISABLE_INPUT_OPERATORS = [
  Operators.IsNotNull,
  Operators.IsNull,
  Operators.LatestPartition,
  Operators.IsTrue,
  Operators.IsFalse,
  Operators.IsEmpty,
  Operators.IsNotEmpty,
  Operators.IsBooleanEmpty,
  Operators.IsBooleanNotEmpty,
];

export const sqlaAutoGeneratedMetricNameRegex =
  /^(sum|min|max|avg|count|count_distinct)__.*$/i;
export const sqlaAutoGeneratedMetricRegex =
  /^(LONG|DOUBLE|FLOAT)?(SUM|AVG|MAX|MIN|COUNT)\([A-Z0-9_."]*\)$/i;

export const TIME_FILTER_LABELS = {
  time_range: t('Time range'),
  granularity_sqla: t('Time column'),
  time_grain_sqla: t('Time grain'),
  granularity: t('Time granularity'),
};

export const FILTER_CONFIG_ATTRIBUTES = {
  CLEARABLE: 'clearable',
  DEFAULT_VALUE: 'defaultValue',
  MULTIPLE: 'multiple',
  SEARCH_ALL_OPTIONS: 'searchAllOptions',
  SORT_ASCENDING: 'asc',
  SORT_METRIC: 'metric',
};

export const FILTER_OPTIONS_LIMIT = 1000;

/**
 * Map control names to their key in extra_filters
 */
export const TIME_FILTER_MAP = {
  time_range: '__time_range',
  granularity_sqla: '__time_col',
  time_grain_sqla: '__time_grain',
  granularity: '__granularity',
};

export const POPOVER_INITIAL_HEIGHT = 400;
export const POPOVER_INITIAL_WIDTH = 320;
export const UNSAVED_CHART_ID = 0;
export const LINE_ZINDEX = 1030;

export const QUERY_MODE_REQUISITES = new Set([
  'all_columns',
  'groupby',
  'metrics',
  'percent_metrics',
  'order_by_cols',
]);

export const UUID_REGEX = new RegExp(
  '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
);

export const EXIT_LABEL = 'Exit';
export const EXIT_COLOR = '#818D92';
