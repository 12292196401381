/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/Button';
import { styled, t /* SupersetClient */ } from '@superset-ui/core';

import ErrorBoundary from 'src/components/ErrorBoundary';
import Tabs from 'src/components/Tabs';
import adhocMetricType from 'src/explore/components/controls/MetricControl/adhocMetricType';
import AdhocFilter from 'src/explore/components/controls/FilterControl/AdhocFilter';
import AdhocFilterEditPopoverSimpleTabContent from 'src/explore/components/controls/FilterControl/AdhocFilterEditPopoverSimpleTabContent';
import AdhocFilterEditPopoverSqlTabContent from 'src/explore/components/controls/FilterControl/AdhocFilterEditPopoverSqlTabContent';
import columnType from 'src/explore/components/controls/FilterControl/columnType';
import Icons from 'src/components/Icons';
import {
  Operators,
  POPOVER_INITIAL_HEIGHT,
  POPOVER_INITIAL_WIDTH,
} from 'src/explore/constants';
import { ExpressionTypes } from '../types';

const propTypes = {
  adhocFilter: PropTypes.instanceOf(AdhocFilter).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      columnType,
      PropTypes.shape({ saved_metric_name: PropTypes.string.isRequired }),
      adhocMetricType,
    ]),
  ).isRequired,
  datasource: PropTypes.object,
  partitionColumn: PropTypes.string,
  theme: PropTypes.object,
  sections: PropTypes.arrayOf(PropTypes.string),
  operators: PropTypes.arrayOf(PropTypes.string),
  requireSave: PropTypes.bool,
  disableFetchFromCache: PropTypes.bool,
  isCustomSqlNotEditable: PropTypes.bool,
  viz_type: PropTypes.string,
};

const ResizeIcon = styled.i`
  margin-left: ${({ theme }) => theme.gridUnit * 2}px;
`;

const FilterPopoverContentContainer = styled.div`
  .adhoc-filter-edit-tabs > .nav-tabs {
    margin-bottom: ${({ theme }) => theme.gridUnit * 2}px;

    & > li > a {
      padding: ${({ theme }) => theme.gridUnit}px;
    }
  }

  #filter-edit-popover {
    max-width: none;
  }

  .filter-edit-clause-info {
    font-size: ${({ theme }) => theme.typography.sizes.xs}px;
  }

  .filter-edit-clause-section {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.gridUnit * 5}px;
  }

  .adhoc-filter-simple-column-dropdown {
    margin-top: ${({ theme }) => theme.gridUnit * 5}px;
  }
`;

const IconWrapper = styled.span`
  span {
    margin-right: ${({ theme }) => 2 * theme.gridUnit}px;
    vertical-align: middle;
  }
  .text {
    vertical-align: middle;
  }
  .error {
    color: ${({ theme }) => theme.colors.error.base};
  }
`;

const FilterActionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.gridUnit * 2}px;
`;

const SQL_TO_OPERATORS = {
  '=': '==',
  '<>': '!=',
  '>': '>',
  '<': '<',
  '>=': '>=',
  '<=': '<=',
  IN: 'IN',
  'NOT IN': 'NOT IN',
  LIKE: 'CONTAINS_SENSITIVE',
  ILIKE: 'CONTAINS',
  'NOT LIKE': 'NOT_CONTAINS_SENSITIVE',
  'NOT ILIKE': 'NOT_CONTAINS',
  REGEX: 'REGEX',
  'IS NOT NULL': 'IS NOT NULL',
  'IS NULL': 'IS NULL',
  'IS TRUE': 'IS TRUE',
  'IS FALSE': 'IS FALSE',
  "= ''": 'IS EMPTY',
  "<> ''": 'IS NOT EMPTY',
};

function removeWrappedQuotes(str) {
  if (/^[ \n]*'/g.test(str) && /'[ \n]*$/g.test(str)) {
    return str.replace(/^ *'/g, '').replace(/' *$/g, '');
  }
  return str;
}

function checkSensitiveColumns(
  sensitiveColumns,
  sqlExpression,
  currentEditTab,
) {
  if (currentEditTab !== 'SQL') return false;
  const subject = sqlExpression?.toLowerCase() || '';
  return sensitiveColumns.find(item => subject.includes(item)) || false;
}

// Check if the sqlExpression is in the format of "SELECT column FROM table"
export function CheckSubQuery(sql, currentEditTab) {
  if (currentEditTab !== 'SQL') return false;
  const selectFromPattern = /SELECT\s+\S+\s+FROM\s+\S+/i;
  return selectFromPattern.test(sql);
}

// Check if the sqlExpression is in the format of "column IN ('a', 'b', 'c')"
export function isColumnInOrNotInFormat(str) {
  const regex =
    /^\s*(\w+)\s+(NOT\s+)?IN\s*\(\s*('[^']+'(\s*,\s*'[^']+')*)\s*\)\s*$/;
  return regex.test(str);
}

// This function is used to change the expressionType of adhocFilter from SQL to SIMPLE.
function ChangeAdhocFilterToSimple(adhocFilter) {
  if (
    adhocFilter.currentEditTab === 'COHORT' ||
    adhocFilter.expressionType === 'SIMPLE'
  ) {
    return adhocFilter;
  }
  const sql = adhocFilter.sqlExpression;
  if (sql.includes('multiIf')) return adhocFilter;
  const sqlArray = sql.split(' ');
  if (sql.endsWith('IS NULL')) {
    if (sqlArray.length === 3) {
      return adhocFilter.duplicateWith({
        subject: sqlArray[0],
        comparator: undefined,
        operator: 'IS NULL',
        operatorId: 'IS_NULL',
        expressionType: 'SIMPLE',
      });
    }
    return adhocFilter;
  }
  if (sql.endsWith('IS NOT NULL')) {
    if (sqlArray.length === 4) {
      return adhocFilter.duplicateWith({
        subject: sqlArray[0],
        comparator: undefined,
        operator: 'IS NOT NULL',
        operatorId: 'IS_NOT_NULL',
        expressionType: 'SIMPLE',
      });
    }
    return adhocFilter;
  }

  if (sqlArray.length >= 3) {
    const subject = sqlArray[0];
    let operator = SQL_TO_OPERATORS[sqlArray[1]];
    let operatorId = Operators[operator];
    let comparator;
    if (
      (operator === 'IN' || operator === 'NOT IN') &&
      !isColumnInOrNotInFormat(sql)
    ) {
      return adhocFilter;
    }
    if (operator === 'IN') {
      // console.log(sql);
      // console.log(sqlArray);
      comparator = sqlArray.slice(2, sqlArray.length).join(' ');
      // console.log(comparator);
      if (!Array.isArray(comparator)) {
        if (comparator.startsWith('(') && comparator.endsWith(')')) {
          comparator = comparator.substring(1, comparator.length - 1);
        }
        // will be a array of string
        // console.log('comparator before comparator.split(\',\')');
        // console.log(comparator);
        comparator = comparator.split(',');
        comparator = comparator.map(function (x) {
          return removeWrappedQuotes(x);
        });
      }
    } else if (sqlArray[1] === 'NOT' && sqlArray[2] === 'ILIKE') {
      // If the sql Array length is greater than 4, the type will be SQL,
      // and will not be changed to SIMPLE.
      if (sqlArray.length > 4) {
        return adhocFilter;
      }
      operator = Operators.NOT_CONTAINS;
      operatorId = Operators.NOT_CONTAINS;
      comparator = sqlArray.slice(3, sqlArray.length).join(' ');
      comparator = removeWrappedQuotes(comparator);
      comparator = comparator.substring(1, comparator.length - 1);
    } else if (sqlArray[1] === 'NOT' && sqlArray[2] === 'LIKE') {
      if (sqlArray.length > 4) {
        return adhocFilter;
      }
      operator = Operators.NOT_CONTAINS_SENSITIVE;
      operatorId = Operators.NOT_CONTAINS_SENSITIVE;
      comparator = sqlArray.slice(3, sqlArray.length).join(' ');
      comparator = removeWrappedQuotes(comparator);
      comparator = comparator.substring(1, comparator.length - 1);
    } else if (
      // If the sql Array length is greater than 3, the type will be SQL,
      // and will not be changed to SIMPLE.
      sqlArray.length > 3 &&
      operator !== 'IN' &&
      operator !== 'NOT IN'
    ) {
      return adhocFilter;
    } else if (sqlArray.length === 3 && operator !== 'IN') {
      comparator = sqlArray[2];
      comparator = removeWrappedQuotes(comparator);
      if (operator === 'CONTAINS') {
        if (comparator.startsWith('%') && comparator.endsWith('%')) {
          operatorId = Operators.CONTAINS;
          comparator = comparator.substring(1, comparator.length - 1);
        } else if (comparator.endsWith('%')) {
          operator = 'STARTS_WITH';
          operatorId = Operators.STARTS_WITH;
          comparator = comparator.substring(0, comparator.length - 1);
        } else {
          operator = 'ENDS_WITH';
          operatorId = Operators.ENDS_WITH;
          comparator = comparator.substring(1, comparator.length);
        }
      }
      if (operator === 'CONTAINS_SENSITIVE') {
        comparator = comparator.substring(1, comparator.length - 1);
      }
      if (operator === '==' && comparator === '') {
        operator = 'IS EMPTY';
        operatorId = 'IS_EMPTY';
        comparator = undefined;
      }
      if (operator === '==' && comparator !== '') {
        operatorId = Operators.EQUALS;
      }
      if (operator === '!=' && comparator === '') {
        operator = 'IS NOT EMPTY';
        operatorId = Operators.IS_NOT_EMPTY;
        comparator = undefined;
      }
      if (operator === '!=' && comparator !== '') {
        operatorId = Operators.NOT_EQUALS;
      }
      if (operator === '>=') {
        operatorId = Operators.GREATER_THAN_OR_EQUAL;
      }
      if (operator === '>') {
        operatorId = Operators.GREATER_THAN;
      }
      if (operator === '<=') {
        operatorId = Operators.LESS_THAN_OR_EQUAL;
      }
      if (operator === '<') {
        operatorId = Operators.LESS_THAN;
      }
    }
    if (subject && operator) {
      return adhocFilter.duplicateWith({
        expressionType: 'SIMPLE',
        subject,
        comparator,
        operator,
        operatorId,
      });
    }
  }
  return adhocFilter;
}

export default class AdhocFilterEditPopover extends Component {
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.onDragDown = this.onDragDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onAdhocFilterChange = this.onAdhocFilterChange.bind(this);
    this.setSimpleTabIsValid = this.setSimpleTabIsValid.bind(this);
    this.setCohortTabIsValid = this.setCohortTabIsValid.bind(this);
    this.adjustHeight = this.adjustHeight.bind(this);
    this.onTabChange = this.onTabChange.bind(this);

    this.newFilter = ChangeAdhocFilterToSimple(this.props.adhocFilter);

    this.state = {
      adhocFilter: this.newFilter,
      width: POPOVER_INITIAL_WIDTH,
      height: POPOVER_INITIAL_HEIGHT,
      activeKey:
        this.props?.adhocFilter?.expressionType ||
        this.newFilter.expressionType ||
        'SIMPLE',
      isSimpleTabValid: true,
      isCohortTabValid: true,
      // savedCohorts: [],
    };

    this.popoverContentRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.onMouseUp);
    this.loadSavedCohorts(this.props.datasource.id);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this.onMouseMove);
  }

  onAdhocFilterChange(adhocFilter) {
    this.setState({ adhocFilter });
  }

  setSimpleTabIsValid(isValid) {
    this.setState({ isSimpleTabValid: isValid });
  }

  setCohortTabIsValid(isValid) {
    this.setState({ isCohortTabValid: isValid });
  }

  onSave() {
    // TODO: 4.1.0 Upgrade - uncomment below for column values
    // try {
    //   // Do score increase here, INCRBY +1
    //   if (this.state.adhocFilter) {
    //     const af = this.state.adhocFilter;
    //     if (!!af.subject && !!af.comparator) {
    //       const ds = this.props.datasource;
    //       const unwrappedCol = af.subject.trim().replace(/^"|"$/g, '');
    //       const { comparator } = af;
    //       const afterIncrbyScore = (json, apiPath) => {
    //         console.log(json);
    //         // Add Telemetry here
    //         const st = new Date().getTime();
    //         NezhaTelemetry.trackEngagementEvent(this.context, {
    //           scenario: parseScenarioFromUri(getPathname()),
    //           operation: NEZHA_OPERATION.API,
    //           customProperties: {
    //             apiUri: apiPath,
    //             responseTime: new Date().getTime() - st,
    //           },
    //         });
    //       };
    //       const exceptionHandler = (error, apiPath) => {
    //         // when the column is not enabled incrby, will return error by design
    //         console.warn(apiPath, error);
    //       };

    //       if (Array.isArray(comparator)) {
    //         // INCRBY + 1 for comparator list
    //         cacheIncrbyScore(
    //           ds.type,
    //           ds.id,
    //           unwrappedCol,
    //           comparator,
    //           afterIncrbyScore,
    //           exceptionHandler,
    //         );
    //       } else if (typeof comparator === 'string') {
    //         // INCRBY + 1 for comparator string
    //         cacheIncrbyScore(
    //           ds.type,
    //           ds.id,
    //           unwrappedCol,
    //           [comparator],
    //           afterIncrbyScore,
    //           exceptionHandler,
    //         );
    //       } else {
    //         // Do Nothing, other type please don't do anthing
    //       }
    //     }
    //   }
    // } catch (error) {
    //   console.warn(error);
    // }

    this.props.onChange(this.state.adhocFilter);
    this.props.onClose();
  }

  onDragDown(e) {
    this.dragStartX = e.clientX;
    this.dragStartY = e.clientY;
    this.dragStartWidth = this.state.width;
    this.dragStartHeight = this.state.height;
    document.addEventListener('mousemove', this.onMouseMove);
  }

  onMouseMove(e) {
    this.props.onResize();
    this.setState({
      width: Math.max(
        this.dragStartWidth + (e.clientX - this.dragStartX),
        POPOVER_INITIAL_WIDTH,
      ),
      height: Math.max(
        this.dragStartHeight + (e.clientY - this.dragStartY),
        POPOVER_INITIAL_HEIGHT,
      ),
    });
  }

  onMouseUp() {
    document.removeEventListener('mousemove', this.onMouseMove);
  }

  onTabChange(activeKey) {
    this.setState(prevState => ({
      activeKey,
      adhocFilter: prevState.adhocFilter.duplicateWith({
        activeTab: activeKey,
      }),
    }));
  }

  adjustHeight(heightDifference) {
    this.setState(state => ({ height: state.height + heightDifference }));
  }

  loadSavedCohorts(/* datasetId */) {
    // SupersetClient.get({
    //   endpoint: `/api/v1/dataset/${datasetId}/cohorts`,
    // }).then(response => this.setState({ savedCohorts: response.json.result }));
  }

  render() {
    const {
      adhocFilter: propsAdhocFilter,
      options,
      onChange,
      onClose,
      onResize,
      datasource,
      partitionColumn,
      theme,
      operators,
      requireSave,
      ...popoverProps
    } = this.props;

    const { adhocFilter } = this.state;
    while (
      adhocFilter.subject &&
      adhocFilter.subject.length >= 2 &&
      adhocFilter.subject.startsWith('"') &&
      adhocFilter.subject.endsWith('"')
    ) {
      adhocFilter.subject = adhocFilter.subject.slice(1, -1);
    }
    const stateIsValid = adhocFilter.isValid();
    // const hasUnsavedChanges =
    //   requireSave || !adhocFilter.equals(propsAdhocFilter);
    const sensitiveColumns = datasource?.sensitive_columns || [];
    const hasSensitiveColumns = checkSensitiveColumns(
      sensitiveColumns,
      adhocFilter?.sqlExpression,
      adhocFilter?.currentEditTab,
    );
    const hasSubQuery = CheckSubQuery(
      adhocFilter?.sqlExpression,
      adhocFilter?.currentEditTab,
    );
    // const showCohortTab = ![
    //   'cohort',
    //   'measure',
    //   'measure_line',
    //   'saved_column',
    // ].includes(this.props.viz_type);

    return (
      <FilterPopoverContentContainer
        id="filter-edit-popover"
        {...popoverProps}
        data-test="filter-edit-popover"
        ref={this.popoverContentRef}
      >
        <Tabs
          id="adhoc-filter-edit-tabs"
          defaultActiveKey={adhocFilter.activeTab || adhocFilter.expressionType}
          className="adhoc-filter-edit-tabs"
          data-test="adhoc-filter-edit-tabs"
          style={{ minHeight: this.state.height, width: this.state.width }}
          allowOverflow
          onChange={this.onTabChange}
          css={{
            'span :focus': {
              outline: '0px !important',
            },
          }}
        >
          {/* {showCohortTab && (
            <Tabs.TabPane
              className="adhoc-filter-edit-tab"
              key={EXPRESSION_TYPES.COHORT}
              tab={t('Cohort')}
            >
              <ErrorBoundary>
                <AdhocFilterEditPopoverCohortTabContent
                  operators={operators}
                  adhocFilter={this.state.adhocFilter}
                  onChange={this.onAdhocFilterChange}
                  options={this.state.savedCohorts}
                  datasource={datasource}
                  onHeightChange={this.adjustHeight}
                  partitionColumn={partitionColumn}
                  popoverRef={this.popoverContentRef.current}
                  validHandler={this.setCohortTabIsValid}
                  disableFetchFromCache={
                    this.props.disableFetchFromCache || false
                  }
                />
              </ErrorBoundary>
            </Tabs.TabPane>
          )} */}
          <Tabs.TabPane
            className="adhoc-filter-edit-tab"
            key={ExpressionTypes.Simple}
            tab={t('Simple')}
          >
            <ErrorBoundary>
              <AdhocFilterEditPopoverSimpleTabContent
                operators={operators}
                adhocFilter={this.state.adhocFilter}
                onChange={this.onAdhocFilterChange}
                options={options}
                datasource={datasource}
                onHeightChange={this.adjustHeight}
                partitionColumn={partitionColumn}
                popoverRef={this.popoverContentRef.current}
                validHandler={this.setSimpleTabIsValid}
                disableFetchFromCache={
                  this.props.disableFetchFromCache || false
                }
              />
            </ErrorBoundary>
          </Tabs.TabPane>
          <Tabs.TabPane
            className="adhoc-filter-edit-tab"
            key={ExpressionTypes.Sql}
            tab={t('Custom SQL')}
          >
            <ErrorBoundary>
              <AdhocFilterEditPopoverSqlTabContent
                isCustomSqlNotEditable={this.props.isCustomSqlNotEditable}
                adhocFilter={this.state.adhocFilter}
                onChange={this.onAdhocFilterChange}
                options={this.props.options}
                height={this.state.height}
                activeKey={this.state.activeKey}
              />
            </ErrorBoundary>
          </Tabs.TabPane>
        </Tabs>
        {this.state.activeKey === ExpressionTypes.Sql &&
          (hasSensitiveColumns || hasSubQuery) && (
            <IconWrapper className="warning">
              {hasSensitiveColumns && (
                <>
                  <Icons.ErrorSolidSmall iconColor="red" />
                  <span className="text error">
                    Custom SQL cannot contain sensitive column "
                    {hasSensitiveColumns}"
                  </span>
                </>
              )}
              <br />
              {hasSubQuery && (
                <>
                  <Icons.ErrorSolidSmall iconColor="red" />
                  <span className="text error">
                    Custom SQL cannot contain sub-queries
                  </span>
                </>
              )}
            </IconWrapper>
          )}
        <FilterActionsContainer>
          <Button buttonSize="small" onClick={this.props.onClose} cta>
            {t('Close')}
          </Button>
          <Button
            data-test="adhoc-filter-edit-popover-save-button"
            disabled={
              !stateIsValid ||
              hasSubQuery ||
              hasSensitiveColumns ||
              !this.state.isSimpleTabValid ||
              !this.state.isCohortTabValid
            }
            buttonStyle="primary"
            buttonSize="small"
            className="m-r-5"
            onClick={this.onSave}
            cta
          >
            {t('Save')}
          </Button>
          <ResizeIcon
            role="button"
            aria-label="Resize"
            tabIndex={0}
            onMouseDown={this.onDragDown}
            className="fa fa-expand edit-popover-resize text-muted"
          />
        </FilterActionsContainer>
      </FilterPopoverContentContainer>
    );
  }
}

AdhocFilterEditPopover.propTypes = propTypes;
